<template>
    <div class="main">
      <div class="art cont-slider">
        <div class="mg-top" style="margin:5rem 0;">
          <div>
            <h1>Preços e orçamentos para<span style="text-transform:uppercase;font:2rem sans-serif,Roboto;color:#000000FC;max-width:100%;">&nbsp;serviços de Mudança</span></h1>
            <div class="banner">
              <p>Veja o serviços oferecidos Mudanças residenciais, Mudanças Comerciais, Pequenos Carretos e Fretes em geral. Peça orçamentos grátis e sem compromisso.</p>
            </div>
            <div> 
              <div class="banner">
                <button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicite orçamento personalizado</button>
              </div>
            </div>
          </div>
          <img style="float:right" src="@/assets/bh.webp" alt="Rei das Mudanças" width="545" height="304">
        </div>
      </div>
      <div class="art cont-slider">
        <div class="mg-top just-c" style="margin:1.25rem 0;">
          <h2>Sobre nos</h2>
        </div>
        <div class="fx">
          <div style="padding:0 1.25rem">
            <p>O Procuro Carreto Online trabalha com qualidade e compromisso, oferecendo comodidade e segurança a todos os clientes que buscam pelos nossos serviços em todo Brasil.</p>
            <p>Com uma frota variada de veículos, contamos com a opção mais adequada para cada tipo de mudança, sempre realizada com rapidez e praticidade e a preços competitivos no mercado. Nossos funcionários são capacitados e especializados em realizar desmontagem e montagem de móveis com agilidade e máximo cuidado, além de embalar seus móveis e pertences com os melhores e mais variados materiais.</p>
          </div>
          <div style="margin:0.25rem 0;padding:0 1.25rem">
            <p>E para oferecer um serviço completo,o Procuro Carreto Online ainda dispõe de depósito para armazenagem,sempre sob rígido controle de vigilância. Entre em contato conosco. Nossa equipe está pronta para encontrar as melhores soluções para sua demanda. Realizamos orçamentos sem compromisso.</p>
          </div>
        </div>
      </div>
      <div class="art cont-slider">
        <div class="mg-top just-c" style="margin:1.25rem 0;">
          <div class="mg-top">
            <h2>Nossos serviços</h2>
          </div>
        </div>  
        <div class="servicos fx">
          <ul>
            <li>
              <div><img src="@/assets/icon-home.svg"></div>
              <div>
                <h2 style="text-transform:uppercase;margin:20px 0;padding-left:10px">Mudanças residenciais</h2>
                <em><p>Fazemos mudanças residenciais, atendemos a região de Campinas e também realizamos mudanças de longas distâncias (interestaduais) com ótimo custo x benefício.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.8755rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li>
            <li>
              <div><img src="@/assets/icon-car.svg"></div>
              <div>
                <h2 style="text-transform:uppercase;margin:20px 0;padding-left:10px">Mudanças comerciais</h2>
                <em><p>Realizamos mudanças comerciais como:mudança de bens, móveis, equipamentos de escritórios, salas comerciais, fábricas e indústrias.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li>
            <!-- <li>
              <div><img src="@/assets/resedencial.jpg"></div>
              <div>
                <h2>Montagem de móveis</h2>
                <em><p>Além do transporte,temos serviço de Desmontagem e Montagem de Móveis para Mudanças Comerciais e Residenciais.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li> -->
            <li>
              <div><img src="@/assets/icon-truck.svg"></div>
              <div>
                <h2 style="text-transform:uppercase;margin:20px 0;padding-left:10px">Carretos em geral</h2>
                <em><p>Você precisa de pequenos transportes em Campinas e região? O Procuro Carreto Online esta pronta para atender você! Temos Pick-up, Van, Caminhão baú.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li>
          </ul>
        </div>
      </div>
      <div>
          <div class="chat">
            <div class="chat-header clearfix">
                  <img src="@/assets/chat_avatar.webp" alt="avatar" width="55" height="55">
                  <div class="chat-about">
                    <div class="chat-with">Chat - Auto Atendimento</div>
                    <div class="chat-num-messages">Online</div>
                  </div>
                  <a @click="cls" class="close"></a>
                  <!-- <i class="fa fa-star"></i> -->
            </div>
                <div class="chat-history">
                  <ul>
                    <li class="clearfix">
                      <div class="message-data align-right">
                        <span class="message-data-time">10:10 AM,Today</span> &nbsp; &nbsp;
                        <span class="message-data-name">Olia</span> <i class="fa fa-circle me"></i>
                      </div>
                      <div class="message other-message float-right">
                        Ola Boa Tarde sou o atendente virtual do Rei do Carreto!
                      </div>
                    </li>
                    <li class="clearfix">
                    <div class="message-data align-right">
                        <span class="message-data-time">10:10 AM,Today</span> &nbsp; &nbsp;
                        <span class="message-data-name">Olia</span> <i class="fa fa-circle me"></i>
                      </div>
                      <div class="message other-message float-right">O que podemos te atender</div>
                    </li>
                </ul>
                </div>
                <div class="chat-message clearfix">
                  <label><textarea name="message-to-send" id="message-to-send" placeholder="Digite sua mensagem aqui" rows="3"></textarea></label>
                  <i class="fa fa-file-o"></i> &nbsp;&nbsp;&nbsp;
                  <i class="fa fa-file-image-o"></i>
                  <button id="btn-chat" @click="chat"><span><svg viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path></svg></span></button>
                </div>
          </div>
      </div>
      <div class="art m-w-100 cont-slider">
        <a href="https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento." class="btn-whats pulse"><span><img src="@/assets/whatsapp-32.svg"></span></a>
        <div class="just-c-c" style="flex-direction:column;">
          <div style="margin-bottom:30px">
            <h2>O que dizem sobre nós</h2>
          </div>
          <div style="display:inline-flex;margin-bottom:30px">
            <div>5,0</div>
            <div>
              <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
            </div>
          </div>
          <!-- <div class="elementor-widget-container">
              <div class="elementor-counter">
                <div>
                  <span>+ </span>
                  <span class="elementor-counter-number" data-duration="2000" data-to-value="341" data-from-value="0" data-delimiter=",">341</span>
                  <span> Comentários no Google</span>
                </div>
              </div>
          </div> -->
          <!-- <div>
            <h2>
              <a href="https://www.google.com/search?q=triunfo+mudancas&amp;oq=triunfo&amp;aqs=chrome.1.69i57j69i59l2j69i60j69i61l3j69i60.6711j0j1&amp;sourceid=chrome&amp;ie=UTF-8#" target="_blank">Ver Avaliações Google</a>
            </h2>
          </div> -->
          <div style="position:absolute;margin-bottom:30px">
            <p>São mais de 3 mil clientes atendido pela nossa empresa</p>
          </div>
        </div>
         <div class="comentario fx">
          <ul>
            <li>
              <div>
                <img src="@/assets/comentario.svg" alt="quote" width="24" height="24">
              </div>
              <div>
                <h4><strong>Flávia Cardia P. da Silva</strong> avaliou:</h4>
              </div>
              <div>
                <p>Excelente serviço, são pontuais, os responsáveis pela mudança são cordiais e fizeram um ótimo trabalho. Me atenderam inclusive com um pedido extra no dia da mudança. Excelentes. Obrigada! </p>
              </div> 
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
            <li>
              <div><img src="@/assets/comentario.svg" alt="quote" width="24" height="24"></div>
              <div><h4><strong>João Ito</strong> avaliou:</h4></div>
              <div><p>Fizeram minha mudança para SC. Foram profissionais, pontuais e cuidadosos. Indico tranquilamente!</p></div>
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
            <!-- <li>
              <div><img src="@/assets/resedencial.jpg"></div>
              <div>
                <h2>Montagem de móveis</h2>
                <em><p>Além do transporte,temos serviço de Desmontagem e Montagem de Móveis para Mudanças Comerciais e Residenciais.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li> -->
            <li>
              <div><img src="@/assets/comentario.svg" alt="quote" width="24" height="24"></div>
              <div><h4><strong>Ernani Lemos</strong> avaliou:</h4></div>
              <div><p>Excelente serviço, nota 10 !!! Realmente o melhor da região.</p></div>
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
          </ul>
         </div>
         <div class="comentario fx">
          <ul>
            <li>
              <div>
                <img src="@/assets/comentario.svg" alt="quote" width="24" height="24">
              </div>
              <div>
                <h4><strong>Adiel Maia</strong> avaliou:</h4>
              </div>
              <div>
                <p>Ótima experiência, são rápidos e a equipe ajuda muito. Vale a pena o valor pago.</p>
              </div> 
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
            <li>
              <div><img src="@/assets/comentario.svg" alt="quote" width="24" height="24"></div>
              <div><h4><strong>Laudiceia Gomes</strong> avaliou:</h4></div>
              <div><p>Equipe agradável, cuidadosa... Atendeu a minha solicitação hoje de forma prestativa demais. Grata pela atenção no momento crítico em q eu estava.</p></div>
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
            <!-- <li>
              <div><img src="@/assets/resedencial.jpg"></div>
              <div>
                <h2>Montagem de móveis</h2>
                <em><p>Além do transporte,temos serviço de Desmontagem e Montagem de Móveis para Mudanças Comerciais e Residenciais.</p></em>
              </div>
              <div class="just-c"><button onclick="window.location.href='https://api.whatsapp.com/send?phone=5519993537939&amp;text=Olá%20Procuro%20Carreto%20e%20Online,%20gostaria%20de%20solicitar%20um%20orçamento.'" style="color:#ffea52;background:#000000FC;font-size:0.938rem;font-weight:600;text-transform:uppercase;fill:#ffea52;border-radius:3.5rem 3.5rem 3.5rem 3.5rem;padding:0.938rem 2.813rem;">Solicitar orçamento</button></div>
            </li> -->
            <li>
              <div><img src="@/assets/comentario.svg" alt="quote" width="24" height="24"></div>
              <div><h4><strong>Gabriela Alves</strong> avaliou:</h4></div>
              <div><p>Trabalho impecável, todo cuidado com nossos bens, agilidade em um valor acessível! Top, Parabéns!!</p></div>
              <div>
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
                <img src="data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.56292 19.1324C6.53075 19.32 6.60786 19.5095 6.76184 19.6214C6.91581 19.7333 7.11994 19.748 7.2884 19.6595L12 17.1824L16.7116 19.6595C16.8801 19.748 17.0842 19.7333 17.2382 19.6214C17.3921 19.5095 17.4692 19.32 17.4371 19.1324L16.5372 13.8859L20.349 10.1704C20.4853 10.0375 20.5343 9.83884 20.4755 9.65783C20.4167 9.47683 20.2602 9.3449 20.0719 9.31754L14.8042 8.55209L12.4484 3.77872C12.3641 3.60805 12.1903 3.5 12 3.5C11.8097 3.5 11.6359 3.60805 11.5516 3.77872L9.19583 8.55209L3.9281 9.31754C3.73976 9.3449 3.58328 9.47683 3.52447 9.65783C3.46566 9.83884 3.51471 10.0375 3.65099 10.1704L7.46276 13.8859L6.56292 19.1324Z' fill='%23FFEA52' stroke='%23F6BA50' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e" width="18" height="18" alt="Procuro Carreto Online">
              </div>
            </li>
          </ul>
         </div>
      </div>
      <div class="art">
      <footer class="footer-distributed">
        <div class="footer-left">
          <h3><span>Procuro</span> Carreto Online</h3>
          <p class="footer-links">
            <a @click="pg(0)"  class="link-1">Home&nbsp;</a>
            <a @click="pg(1)">Quem somos&nbsp;</a>
            <a @click="pg(2)">Serviços</a>
          </p>
          <p class="footer-company-name">&copy;&nbsp;{{year}},&nbsp;Todos&nbsp;os&nbsp;direitos&nbsp;reservados</p>
        </div>
        <div class="footer-center">
          <div style="margin-bottom:20px"> 
            <img src="@/assets/local.svg" width="24" height="24">
            <p>Campinas / Sp</p>
          </div>
          <div>
            <a style="margin-bottom:20px" href="tel:5519993537939"><span style="color:#fff;font-size:24px"><img src="@/assets/phone-32.svg" width="24" height="24">&nbsp;(19)&nbsp;9&nbsp;9353-7939</span></a>
            <!-- <p style="font-size:24px; vertical-align: middle;">&nbsp;(19)&nbsp;9&nbsp;9353-7939</p> -->
          </div>
          <div>
            <i class="fa fa-envelope"></i>
            <p><a href="mailto:reidasmudancasecarretos@gmail.com">procurocarretoonline@gmail.com</a></p>
          </div>
        </div>
        <div class="footer-right">
          <p class="footer-company-about">
            <span>Sobre a empresa</span>
            Procuro Carreto Online atua em mudanças residenciais e comerciais para todo o territorio nacional. Oferecemos Segurança, eficiência e rapidez. Tudo bem planejado e executado por profissionais qualificados.
          </p>
          <div class="footer-icons">
            <span>Siga-nos</span>
          </div>
          <div class="footer-icons">
            <a href="https://www.instagram.com/procurocarretoonline/"><span><img src="@/assets/instagram-logo-32.svg" width="32" height="32"></span></a>&nbsp;
            <a href="https://www.facebook.com/procurocarretoonline/"><span><img src="@/assets/facebook-logo-32.svg" width="32" height="32"></span></a>
          </div>
        </div>
      </footer>
    </div>
    </div>
</template>
<script>
export default {
  name:'main',
  data:function () {
    return {
      year:''
    }
  },
   mounted() {
    document.querySelector('.main').addEventListener('scroll',this.handleScroll)
   },
  created () {
    this.year = new Date().getFullYear()
  },
  methods:{
    mouseover(){
      let box =document.getElementById('box');
                  document.onmousemove = function(e){
                      box.style.transform = "rotateX(-30deg) rotateY("+ e.clientX +"deg)"
                  }
    },
    mouseleave(){
                  document.onmousemove = false
    },
    handleScroll(){
      
      if (document.querySelector('.main').offsetWidth<=764) {
      if (document.querySelector('.main').scrollTop>10 ) {
        document.querySelector('.logo').classList.add('hide')
        document.querySelector('.main').classList.add('hide')
        document.querySelector('.phone').classList.add('show')
      } else {
        document.querySelector('.logo').classList.remove('hide')
        document.querySelector('.main').classList.remove('hide')
        document.querySelector('.phone').classList.remove('show')
      }
      }
      /* const triggerBottom = (window.innerHeight / 5) * 4
      document.querySelectorAll('.menu').forEach((box) => {
        if (box.getBoundingClientRect().top < triggerBottom) {
          box.classList.add('hide')
        }
      }) */
    },
    pg (x) {
      if (x==5) {
        document.querySelector('.chat').classList.add('hide')
        if (document.querySelector('.chat').requestFullscreen) {
          document.querySelector('.chat').requestFullscreen();
        } else if (document.querySelector('.chat').webkitRequestFullscreen) { /* Safari */
          document.querySelector('.chat').webkitRequestFullscreen();
        } else if (document.querySelector('.chat').msRequestFullscreen) { /* IE11 */
          document.querySelector('.chat').msRequestFullscreen();
        }
      }
      else {
      document.querySelector('.main').scrollTo(0,document.querySelectorAll('.art')[x].offsetTop)
      return
      }
    },
    cls(){
      document.querySelector('.chat').classList.remove('hide')
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.x=null
    },
    chat () {
      document.getElementById('message-to-send').focus()
      var messageResponses= [
          'Ola Boa Tarde sou o atendente virtual do Rei do Carreto',
          'How do you comfort a JavaScript bug? You console it.',
          'An SQL query enters a bar,approaches two tables and asks:"May I join you?"',
          'What is the most used language in programming? Profanity.',
          'What is the object-oriented way to become wealthy? Inheritance.',
          'An SEO expert walks into a bar,bars,pub,tavern,public house,Irish pub,drinks,beer,alcohol'
        ]
      var m = document.getElementById('message-to-send').value
      var chatHistoryList = Array.from(document.querySelectorAll('.chat-history>ul>li')) 
      if (chatHistoryList.length==0) {
        setTimeout(function() {
          document.querySelector('.chat-history>ul').innerHTML+='<li><div class="message-data"><span class="message-data-name"><i class="fa fa-circle online"></i> Vincent</span><span class="message-data-time">'+h.getHours()+':'+h.getMinutes()+',Today</span></div><div class="message other-message">'+messageResponses[0]+'</div></li>'
        },500);
      } 
      else {
      var h = new Date();
      setTimeout(function() {document.querySelector('.chat-history>ul').innerHTML+='<li><div class="message-data"><span class="message-data-name"><i class="fa fa-circle online"></i> Vincent</span><span class="message-data-time">'+h.getHours()+':'+h.getMinutes()+',Today</span></div><div class="message my-message">'+m+'</div></li>'
        document.getElementById('message-to-send').value=""
        document.getElementById('message-to-send').focus()
        const chatHistory = document.querySelector('.chat-history') 
        chatHistory.scrollTop = chatHistory.scrollHeight - chatHistory.clientHeight
      },500);
      }
    }
  }
}
</script>
<style lang="scss">
@font-face {
  font-family:"Poppins";
  src:local('../assets/fonts/roboto-v29-latin-regular.eot');
  src:local('../assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
       local('../assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'),
       local('../assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
       local('../assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'),
       local('../assets/fonts/roboto-v29-latin-regular.svg#Poppins') format('svg');
  font-display:swap
}
@font-face {
  font-family:"Roboto";
  src:local('../assets/fonts/roboto-v29-latin-regular.eot');
  src:local('../assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
       local('../assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'),
       local('../assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
       local('../assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'),
       local('../assets/fonts/roboto-v29-latin-regular.svg#Poppins') format('svg');
  font-display:swap
}
$a:absolute;
$f:fixed;
$fx:flex;
$n:none;
$bk:block;
$r:relative;
$l:left;
$cl:column;
$c1:#000000FC;
$c2:#fff;
$c3:#52c158;
$c4:#0c0c0c;
$c5:#717171;
$c6:#ffea52;
$c7:#f8f8f8;
$c8:#3d3d3c;
$c9:#001f2f;
$p:pointer;
$p_100:100%;
$m1:0.625rem;
$m2:4.375rem;
$m3:3.125rem;
$m4:2.188rem;
$m5:1rem;
/* $c3:#86BB71;
$c1:#94C2ED;
$c6:#E38968;
$c5:#92959E; */
* {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  text-decoration:none;
  color:#3d3d3c;
  border:none;
  margin:0; 
  padding:0;
  font-family:'Roboto';
  position:relative;
  max-width:100%
}
em p{
  max-width: 90vw;
  padding:10px;
}
button,a:hover{
 cursor: $p;
}
  .btn-whats{
    background-color:$c3;
    color:white;
    width:1.875rem;
    height:1.875rem;
    text-decoration:none;
    border-radius:50rem;
    padding:1.25rem;
    bottom:$m1;
    right:$m5;
    position:fixed;
    transition:all .6s;
    animation:pulse 2s infinite;
    z-index:1;
  }
  .btn-whats:hover{
    background:$c8;
    box-shadow:0 0 12.5rem rgba(0,0,0,.2);
  }
  .pulse{
    animation:pulse 2s infinite
  }
  .btn-whats > img{
    position:relative;
    font-size:2.4rem;
    margin:-0.313rem -0.063rem;
  }
  h1{font:1.875rem Roboto,sans-serif;color:$c5}
  h2{color:$c1;font-size:1.25rem}
  h2 a{color:$c1;font-size:1.25rem}
  .main{
    top:25vh;
    min-width:98vw;
    max-height:75vh;
    flex-direction:column;
    padding:0 1vw;
    overflow:scroll;
    overflow-x:hidden
  }
  .main.hide{
    top:5vh;
    max-height:95vh;
  }
  .main.show{
    top:25vh;
    max-height:75vh;
  }
.chat {
  min-width:98vw!important;
  max-width:100vw!important;
  overflow:hidden!important;
  max-height:0vh!important;
  // transition:max-height 1s ease-in-out;
  position:$f;
  top:0!important;
  right:0;
  z-index:6;
  background:$c2;
  // border-radius:0.313rem;
  // color:$c5;
  .chat-header {
    // position:relative;
    min-width:100%!important;
    padding:2vh 0;
    vertical-align:middle;
    background:$c6;
    .close {
      float:right!important;
      right:1vw;
      width:48px;
      height:48px;
      opacity:0.3;
      padding:1vh 1vw;
    &:hover {
      opacity:1!important
    }
    &:before,&:after {
      position:absolute;
      left:50%;
      top:25%;
      content:' ';
      height:2.063rem;
      width:0.125rem;
      background-color:#333
    }
    &:before {
      transform:rotate(45deg)
    }
    &:after {
      transform:rotate(-45deg)
    }
  }
    img {
      float:left;
      width:48px;
      height:48px;
      padding:1vh 1vw
    }
    .chat-about {
      float:left;
      height:48px;
      padding:1vh 1vw
    }
    .chat-with {
      font-size:18px
    }
    .chat-num-messages {
      color:$c5
    }
    .fa-star {
      float:right;
      color:#D8DADF;
      font-size:1.25rem;
      margin-top:2,2140221402214024vh
    }
  }
  .chat-history {
    padding:1.875rem 1.875rem 1.25rem;
    overflow-y:auto;
    min-width:88%!important;
    min-height:72%!important;
    max-height:72%!important;
    .message-data {
      margin-bottom:0.938rem
    }
    .message-data-time {
      color:lighten($c5,8%);
      padding-left:0.3755rem
    }
    .message {      
      color:white;
      padding:18px 1.25rem;
      line-height:1.625rem;
      font-size:1rem;
      border-radius:0.438rem;
      margin-bottom:1.875rem;
      width:90%;
      position:relative;
      &:after {
        bottom:100%;
        left:7%;
        border:solid transparent;
        content:"";
        height:0;
        width:0;
        position:absolute;
        pointer-events:none;
        border-bottom-color:$c3;
        border-width:0.625rem;
        margin-left:-0.625rem
      }
    }
    .my-message {
      background:$c3
    }
    .other-message {
      background:$c1;
      &:after {
        border-bottom-color:$c1;
        left:93%
      }
    }
  }
  .chat-message {
    background:$c2;
    display:inline-flex;
    position:absolute;
    align-items:center;
    bottom:0;
    min-width:100vw;
    /* min-height:10%!important;
    max-height:10%!important; */
    padding:1vh 0;
    label {
      background:$c2;
      margin:1vw;
      min-width:80%!important;
      max-width:80%!important; 
      position:relative;
      display:inline-block;
      height:2.5rem;
      border-radius:62.5rem;
      border:0.125rem solid $c1
    }
    textarea {
      position:relative;
      top:50%;
      left:1.25rem;
      max-width:98%;
      max-height:1.375rem;
      resize:none;
      transform:translateY(-50%);
      font:0.8755rem/1.375rem"Lato",Arial,sans-serif;
      border:0;
      resize:none;
      overflow:hidden
    }
    textarea:focus {
      outline:0
    }
    /* textarea {
      display:table-cell;
      margin:1vw;
      min-width:70%;
      max-width:98vw!important; 
      border:none;
      font:0.8755rem/1.375rem"Lato",Arial,sans-serif;
      border-radius:62.5rem;
      resize:none;
      vertical-align:middle;
    } */
    .fa-file-o,.fa-file-image-o {
      font-size:1rem;
      color:gray;
    }
    button {
      float:right;
      top:50%;
      background:transparent;
      border:none;
      &:hover {
        color:darken($c1,7%)
      }
      .pulse{
       animation:pulse 2s infinite;
      }
    }
    button span svg {
      color:$c2
    }
  }
}
.online,.offline,.me {
    margin-right:0.25rem;
    font-size:0.625rem
}
.online {
  color:$c3
}
.offline {
  color:$c6
}
.me {
  color:$c1
}
.align-right {
  text-align:right
}
.float-right {
  float:right
}
.clearfix:after {
	visibility:hidden;
	display:block;
	font-size:0;
	content:"";
	clear:both;
	height:0
}
.art{
  
  background:$c7;
  padding:2.5rem 0.3755rem
}
.art:nth-child(2){
  background:$c2
}
.art:nth-child(5){
  background:$c2
}
.art:nth-child(6){
  padding:0;
  background:transparent
}
.cont-slider{
  min-height:100vh;
  overflow:hidden
}
.slider{
  animation:sliding 25s ease-out 0s backwards infinite;
  position:$a;
  width:500%;
  height:$p_100
}
.slide{
  display:inline-block;
  width:20%;
  height:$p_100;    
  background-attachment:$f;
  background-position:center center;
  background-size:cover
}
.menu-icon .navicon {
  background:$c1;
  height:0.125rem;
  position:$r;
  transition:background .2s ease-out;
  width:2rem
}
.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background:$c1;
  content:'';
  display:$bk;
  height:100%;
  position:$a;
  transition:all .2s ease-out;
  width:100%
}
.menu-icon .navicon:before {
  top:0.313rem
}
.menu-icon .navicon:after {
  top:-0.313rem
}
.servicos{
  ul {
    list-style:none;
    margin:0;
    li {
      width:100vw;
      height:80vh;
      display:flex;
      flex-direction: column;
      border-radius:0.438rem;
      -webkit-box-shadow:0 0.25rem 0.75rem rgb(0 0 0 / 12%);
      box-shadow:0 0.25rem 0.75rem rgb(0 0 0 / 12%);
      background:$c2;
      margin-top:30px;
      overflow:hidden
    }
    li div:nth-child(1){
      padding-top: 2vh;
      display: flex;
      justify-content: center
  
    }
    li div:nth-child(2) h2{
      min-width: none;
      padding-top: 2vh;
      display: flex;
      justify-content: center
  
    }
    li div:nth-child(3){
      position: $a;
      width: 100vw;
      display: flex;
      align-items: center;
      top:80%
    }
   /*  li div:nth-child(1){
      top:0
    }
    li div:nth-child(2){
      font: 2rem;
      top:50%
    }
    li div:nth-child(3){
      top:85%
    } */
  }
}
.comentario{

 li{
    margin: 0;
    margin-top:10px;
    max-width: 97%;
    padding: 1vw;
  }
     
  li div:nth-child(2){
    margin-bottom:6px ;
  }
  li div:nth-child(4){
    margin-top:6px ;
  }


}
.c4{
  white-space:normal;
  max-width:calc(25% - 2.625rem);
  margin:0 0.063rem;
  padding:0 1rem;
  border:0.063rem solid #52c158
}
.mg-top{
  margin-top:$m3
}
.ds{
  transform:translateY(400%);
  transition:transform 0.4s ease
}
.ds:nth-of-type(even){
  transform:translateY(-400%)
}
.ds.show{
  transform:translateY(0)
}
.chat.hide {
  min-height:100vh!important;
  max-height:100vh!important
  // padding-bottom:1vh;
  // pointer-events:$n;
}
/* footer*/
.footer-distributed{
  position: $fx;
  left: -1vw;
  bottom: -2vw;
	background:$c1;
	box-sizing:border-box;
	min-width:100vw;
	text-align:left;
	font:1rem sans-serif;
	padding:2.5rem 2.5rem;
  margin-bottom:1vw
}
.banner{
  padding:$m1
}
.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display:inline-block;
	vertical-align:top
}
.footer-distributed .footer-right p em{
	color:$c5
}
.footer-distributed .footer-right p em span{
  text-transform:uppercase;
	color:$c6
}
/* Footer left */
.footer-distributed .footer-left{
	width:40%
}
@keyframes animate{
    0%{
        transform:rotateX(-30deg) rotateY(360deg)
    }
    100%{
        transform:rotateX(-30deg) rotateY(0deg)
    }
}
/* The company logo */
.footer-distributed h3{
	color:#92999f;
	font:normal 2rem 'Open Sans',Roboto;
	margin:0
}
.footer-distributed h3 span{
	color:$c6
}
/* Footer links */
.footer-distributed .footer-links{
	color:$c2;
	margin:1.25rem 0 0.75rem;
	padding:0
}
.footer-distributed .footer-links a{
  color:$c6;
	display:inline-block;
	line-height:1.8;
  font-weight:400;
	text-decoration:none
}
.footer-distributed .footer-company-name{
	color:#92999f;
	font-size:1rem;
	font-weight:normal;
	margin:0
}
/* Footer Center */
.footer-distributed .footer-center{
	width:35%
}

.footer-distributed .footer-center i{
	background-color:$c6;
  color:$c6;
	color:$c2;
	font-size:1.563rem;
	width:2rem;
	height:2rem;
	border-radius:50%;
	text-align:center;
	line-height:2.625rem;
	margin:0.625rem 0.938rem;
	vertical-align:middle
}
.footer-distributed .footer-center i.fa-envelope{
	font-size:1.063rem;
	line-height:2rem
}
.footer-distributed .footer-center p{
	display:inline-block;
	color:$c2;
  font-weight:400;
	vertical-align:middle;
	margin:0
}
.footer-distributed .footer-center p em{
	color:$c5
}
.footer-distributed .footer-center p span{
	display:block;
	font-weight:normal;
	font-size:0.8755rem;
	line-height:2
}
.footer-distributed .footer-center p a{
	color:$c6;
	text-decoration:none
}
.footer-distributed .footer-links a:before {
  content:"|";
  font-weight:300;
  font-size:1.25rem;
  left:0;
  color:$c2;
  display:inline-block;
  padding-right:0.313rem
}
.footer-distributed .footer-links .link-1:before {
  content:none
}
/* Footer Right */
.footer-distributed .footer-right{
	width:20%
}
.footer-distributed .footer-company-about{
	line-height:1.25rem;
	color:#92999f;
	font-size:0.875rem;
	font-weight:normal;
	margin:0
}
.footer-distributed .footer-company-about span{
	display:block;
	color:$c2;
	font-size:0.8755rem;
	margin-bottom:1.25rem
}
.footer-distributed .footer-icons{
	margin-top:1.563rem
}
.footer-distributed .footer-icons span{
  text-transform:uppercase;
  color:$c6
}
.footer-distributed .footer-icons a{
	display:inline-block;
  color:$c6;
	border-radius:0.125rem;
	// font-size:1.25rem;
	// text-align:center;
	// line-height:2rem;
	margin-right:0.25rem;
	margin-bottom:0.313rem
}
.footer-distributed .footer-icons a img{
  color:$c6
}
/* If you don't want the footer to be responsive,remove these media queries */
@media (max-width:880px) {
	.footer-distributed{
		font:0.8755rem sans-serif
	}
	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display:block;
		width:100%;
		margin-bottom:2.5rem;
		text-align:center
	}
  .footer-center div:nth-child(1){
  
  display:inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}
.footer-center div:nth-child(2){
  
  display:inline-flex;
  justify-content: center;
  align-items: center;
  
}
	.footer-distributed .footer-center i{
		margin-left:0
	}
}
.just-c-c{
  display:$fx;
  align-items:center;
  justify-content:center
}
.just-c{
  display:$fx;
  justify-content:center
}
.m-w-100{
  min-width:$p_100
}
@media(min-width:60em) {
  
.fx{
  display:inline-flex;
  margin-bottom:6px;
}
.servicos{
    ul {
    list-style:none;
    display: flex;
    li{
      display: flex;
      flex-direction: column!important;
      justify-content: space-between!important;
      width:calc((100vw / 3) - 0.625rem * 3);
      height:32vw;
      margin-left:0.625rem;
    }
    li div{
      position:$a;
      width:100vw;
      max-height:calc(100vw / 3);
      word-wrap: break-word;     
      }
    li div:nth-child(2) em{
      max-height:10px;
    }
    li div:nth-child(2) p{
      padding:6px 4px;
      font-size:1,3020833333333333vw;
    }
    li div:nth-child(1){
      top:0
    }
    li div:nth-child(2){
      font: 2rem;
      top:50%
    }
    li div:nth-child(3){
      top:85%
    }
  }

}
.comentario{
    ul {
    list-style:none;
    display: flex;
    li{
      display: flex;
      flex-direction: row !important;
      justify-content: space-between!important;
      max-width:calc((100vw / 3) - 0.625rem * 3);
      min-width:calc((100vw / 3) - 0.625rem * 3);
      height:25vh;
      margin-left:0.625rem;
    }
    li div{
      position:$a;
      width:100vw;
      max-height:calc(100vw / 3);
      word-wrap: break-word;     
    }
    
    li div:nth-child(3) p{
      padding:6px 4px;
      font-size:1,3020833333333333vw;
    }
    li div:nth-child(1){
      top:1%
    }
    li div:nth-child(2){
      font: 2rem;
      top:15%
    }
    li div:nth-child(3){
      top:30%
    }
    li div:nth-child(4){
      top:70%
    }
  }

}

.chat{
  min-width:23vw!important;
  max-width:25vw!important;
  right:2vw
}
.title{
  margin-bottom:$m3
}
.btn{
  bottom:5rem;
  right:$m4
}
}
</style>