// npm i -D sass
<template>
  <div class="header"  style="position:fixed;width:100vw;left:0;right:0;top:0;z-index:3;display:flex;flex-direction:column;">
    <div class="logo" style="background:#000000FC; float:left; padding:8px 18px;z-index:999;">
      <!-- <img src="@/assets/logo.webp" alt="Girl in a jacket" width="100" height="100"> -->
    </div>
    <div style="display:flex;align-items:center;justify-content: space-between!important;background:#ffea52;width:100vw;">
        <a class="phone" style="left:20px;position:$a" href="tel:5519988830179"><span style="color:#000000FC;font-size:24px"><img src="@/assets/phone.svg" style="color:#000000FC" width="24" height="24">&nbsp;(19)&nbsp;9&nbsp;9353-7939</span></a>
        <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    </div>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <div class="menu" style="overflow:hidden;width:100vw;background:#fff;" v-on:scroll="handleScroll">
      <div class="menu-sub" style="margin-bottom:4px;background:#fff;">
        <a @click="exec(0)">HOME</a>
        <a @click="exec(1)">QUEM SOMOS</a>
        <a @click="exec(2)">SERVIÇOS</a>
        <a @click="exec(3)">AVALIAÇÕES</a>
        <a @click="exec(4)">CONTATO</a>
      </div>
    </div>
    <!-- <a class="btn  display-4 btn-info" href="https://api.whatsapp.com/send?phone=5519988830179&amp;text=Olá%20Rei%20Das%20e%20Mudanças,%20gostaria%20de%20um%20orçamento.">Atendimento via WhatsApp</a>             -->
  </div>
  <hello-world ref="child"></hello-world>      
</template>
//"^(\s)*$\n"
// async type="module"
<script>
import HelloWorld from './components/HelloWorld.vue'
export default{
  components:{ HelloWorld },
  name:'app',
  data:function(){
    return{
      page:null
    }
  },
  
  methods:{
    exec(n){
      this.$refs.child.pg(n)
      document.querySelector('.menu-btn').checked=false
      this.page=n
      return
    }
  }
}
</script>
<style lang="scss">
$a:absolute;
$f:fixed;
$n:none;
$bk:block;
$r:relative;
$l:left;
$c1:#000000FC;
$c2:#fff;
$p:pointer;
html body {
  overflow: hidden;
  max-width: 100vw!important;
  max-height: 100vh!important;
}
.logo.hide{
  display: $n;
}
.phone{
  visibility: hidden;
}
.phone.show{
 visibility: visible
}
.header{
  text-align: left;
 }
.menu a{
  display:$bk;
  padding:20px 20px;
}
.menu a:hover{
  cursor:$p;
  padding-bottom:18px;
  border-bottom:2px solid $c1;
}
.menu{
  background:$c2;
  clear:both;
  max-height:0;
  transition:max-height .2s ease-out;
}
.menu a{
  color:$c1;
}
.menu-icon{
  cursor:$p;
  display:inline-flex;
  // float:right;
 
  padding:28px 20px;
  margin:2px 10px;
  user-select:$n;
}
.menu-icon .navicon{
  background:$c1;
  height:2px;
  position:$r;
  transition:background .2s ease-out;
  width:32px;
}
.menu-icon .navicon:before,
.menu-icon .navicon:after{
  background:$c1;
  content:'';
  display:$bk;
  height:100%;
  position:$a;
  transition:all .2s ease-out;
  width:100%;
}
.menu-icon .navicon:before{
  top:5px;
}
.menu-icon .navicon:after{
  top:-5px;
}
.menu-btn{
  display:$n;
}
.menu-btn:checked ~ .menu{
  max-height:320px;
}
.menu-btn:checked ~ .menu-icon .navicon{
  background:transparent;
}
.menu-btn:checked ~ .menu-icon .navicon:before{
  transform:rotate(-45deg);
}
.menu-btn:checked ~ .menu-icon .navicon:after{
  transform:rotate(45deg);
}
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after{
  top:0;
}
@media(min-width:48em) /* 48em=768px */{
 .phone{
   max-height: 0;
  visibility: hidden;
}
  .menu{
    
    background:#ffea52!important;
    max-height:$n;
  }
  .menu a{
    float:$l;
  }
  .menu-icon{
    display:$n;
  }
}
</style>